.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Start hidden */
  width: 250px;
  height: 100vh;
  background-color: #000;
  transition: transform 0.3s ease;
  z-index: 50;
}

.sidebar.open {
  transform: translateX(250px);
}

.creditAvailable {
  color: #E7E7FF;
  font-weight: 600;
  letter-spacing: 0.4px;
  border: 0;
  border-radius: 50px;
  background: transparent;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px 16px;
  margin-top: 10px;
}

.dollarsign {
  color: #E7E7FF;
  border-radius: 100%;
  padding: 4px 8px;
  border: 0px;
  margin: 5px;
}

.mlm {
  display: flex;
  align-items: center;
  margin-right: 8px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.credit_numbers {
  color: #E7E7FF;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 0 !important;
}

@media screen and (max-width: 500px) {
  .creditAvailable {
    padding: 4px 0px;
  }
}
