.ReactModal__Content {
  position: relative;
  outline: none;
  background: none;
  border: none;
  padding: 0;
}
@keyframes dots {
  0% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.dots-animation::after {
  content: '';
  display: inline-block;
  animation: dots 1.5s steps(4, end) infinite;
  margin-left: 4px; /* Spacing between text and dots */
}
