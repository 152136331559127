/* COMMENT FEATURE */

.item-active {
  background-color: #0d1525;
}

.comment-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: column;
}

.comment-box-wrapper {
  width: 100%;
  padding: 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.profile-img {
  padding: 2px;
  background-color: white;
  border-radius: 100%;
}

.comment-input {
  width: 480px;
  padding: 8px 10px;
  border-radius: 0.75rem;
  outline: none;
  background-color: transparent;
  border: 1px solid rgb(201, 201, 201);
  color: #cdcdcd;
}

.comment-input__input {
  outline: none;
  padding: 9px;
}

.comment-section {
  width: 100%;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.comment-div {
  width: 100%;
  display: flex;
  gap: 0.75rem;
  justify-content: start;
  align-items: start;
  color: #bdbdbd;
}

.comment-profile {
  width: auto;
  height: auto;
  padding-top: 2px;
  display: flex;
  align-items: start;
  justify-content: center;
}

.comment-content {
  min-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.comment-details {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.comment-details>p {
  color: #ababab;
  font-weight: 700;
  font-size: 0.85rem;
}

.comment-details>h5 {
  font-size: 1rem;
  font-weight: 600;
}

.comment-content>p {
  font-size: 0.95rem;
}

.comment-btns {
  display: flex;
  width: 24%;
  align-items: center;
  gap: 1.25rem;
  margin-top: 4px;
}

.comment-btn {
  font-size: 0.85rem;
  color: gray;
  font-weight: 600;
}

.comment-liked {
  color: rgb(57, 57, 174);
}

.reply {
  margin-top: 6px;
}