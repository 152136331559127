.comment-box-enter {
  opacity: 0;
  transform: translateY(100%);
}
.comment-box-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
.comment-box-exit {
  opacity: 1;
  transform: translateY(0);
}
.comment-box-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition:
    opacity 300ms,
    transform 300ms;
}
