.profile_dropdown {
  width: 240px;
  background-color: #1b2333;
  border-radius: 8px;
  display: grid;
  border: 1px solid #444647;
  position: absolute;
  z-index: 100;
  top: 7vh;
  right: 0vh;
}

.dropdown_section1 {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 10px; 
  overflow: hidden;
}

.dropdown_section2 {
  padding: 12px 16px;
}

.dropdown_section3 {
  display: grid;
  grid-gap: 10px;
  padding: 12px 16px;
}

.dropdown_section4 {
  display: grid;
  grid-gap: 10px;
  padding: 12px 16px;
}

.profile_icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background-color: #fefefe;
  border-radius: 50%;
  padding: 1.4px;
  cursor: pointer;
}

.name_paln {
  flex: 1; /* Allows the text block to grow and fill space */
  overflow: hidden; /* Ensures no overflow */
}

.icon_name {
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  color: #cfd4d9;

}

.plan {
  font-size: 12px;
  margin: 0;
  color: #aeb5bc; /* Subtle color for plan text */
}

.view_profile {
  font-size: 14px;
  color: #cfd4d9;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.view_profile:hover {
  color: #a5a1f7;
}

.line1 {
  border: 1px solid #444647;
  height: 1px;
}

.Profile_link {
  text-decoration: none;
}

@media screen and (max-width: 457px) {
  .profile_dropdown {
    width: 191px;
    margin-left: -2%;
    right: -3vh;
  }
  .dropdown_section1{
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 370px) {
  .profile_dropdown {
    width: 200px;
  }
}
