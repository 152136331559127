.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.emaillkp {
  display: flex;
  justify-content: center;
  width: 100%;
}

.loginregister {
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.7px;
  color: #4bc25e;
}

.email-display {
  text-align: center;
  color: #cdcdcd;
  border: 1px solid #cdcdcd;
  padding: 8px 32px;
  border-radius: 60px;
  font-size: 13px;
  letter-spacing: 0.3px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
}

.buttonkjk {
  width: 200px;
  padding: 8px;
  background-color: #4bc25e;
  color: white;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1px;
  letter-spacing: 0.6px;
  place-self: center;
}

.hrlines {
  margin-top: 30px;
}

.ljkn {
  display: flex;
  justify-content: center;
  width: 317px;
}

.kjdsh {
  width: 100%;
}

.sdhbc {
  display: flex;
  justify-content: center;
}

.buttonkjk[type="submit"]:hover {
  background-color: #2a7937;
}

.haAclf {
  display: inline-block;
  padding: 2px 77px;
  display: flex;
}

.nsm7Bb-HzV7m-LgbsSe {
  width: 516px;
  border: 2px solid;
  background: #404076;
}
.ReactModal__Content {
  position: relative;
  outline: none;
  background: none;
  border: none;
  padding: 0;
}
.inputBar1 {
  width: 100%;
  padding: 10px;
  color: white;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  background-color: #0d1525;
  border: 1px solid #8899a8;
}
.linearGradient {
  background: linear-gradient(
    to top,
    rgb(0 0 0 / 93%) 11.2%,
    rgb(28 28 28 / 0%) 78.9%
  );
}

.eyeIcpn {
  cursor: pointer;
  width: 19px;
  color: #7a7a7a;
  position: absolute;
  margin-top: 18px;
  margin-left: -30px;
}

.floeuy {
  display: flex;
}

.reegistehg {
  color: #4bc25e;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: center;
  margin-top: 10px;
}

.hjjwej {
  margin-top: 13px;
  display: grid;
  grid-gap: 10px;
}

.error-message {
  color: #e22424;
  width: 320px;
  text-align: center;
  font-size: 14px;
}

.swicthreg {
  color: #0c0563;
  margin-top: 15px;
  cursor: pointer;
  font-style: inherit;
  letter-spacing: 0.6px;
  font-size: 14px;
}

@media screen and (max-width: 756px) {
  .inputBar1 {
    border-radius: 12px;
    font-size: 14px;
  }
  .error-message {
    margin-top: 4px;
    font-size: 12px;
  }
}
