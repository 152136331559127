.header_component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0px 26px;
  background: #1b2333;
  width: 100%;
}

.main_header_component {
  width: 100%;
  position: relative;
}

.explore_icon {
  color: #8edb8c;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.notification_icon {
  position: relative;
  color: #88d786;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.notification_icon:hover {
  transform: scale(1.1);
}

.hamburger_btns a.active {
  color: #88d786;
}

.mainLogoIP {
  width: 50px;
  border-radius: 60px;
  background-color: #0d1525;
}

.najvbh {
  display: flex;
  justify-content: space-between;
}

.navank {
  font-size: 14.5px;
  color: #cdcdcd;
  margin-left: 16px;
  letter-spacing: 0.8px;
  display: flex;
}

.active {
  color: #6cd97e;
}

.nav-icon {
  margin: 0px 8px;
}

.kjndhsjv {
  display: flex;
  width: 100%;
}

.mainLogoIPs {
  width: 54px;
  border-radius: 60px;
  background-color: #0d1525;
}

.logosname1 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 2px 0px;
}

.btn_item_gig {
  display: flex;
  margin: 7px 10px;
}

.user_profile_social {
  width: 42px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 2px solid white;
}

.header-profile {
  width: 50px;
}

.exploreing {
  text-align: center;
  color: #ffffff;
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 50px;
  background: transparent;
  margin-right: 15px;
  height: 38px;
  border: 0;
  display: flex;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  backdrop-filter: blur(10px); /* Blur effect */
  z-index: 1000;
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow-y: auto;
  background: #333;
  z-index: 10; /* Ensure the sidebar is on top of the backdrop */
}

.notifh {
  width: 19px;
  height: 19px;
  margin: 12px 40px;
  cursor: pointer;
}

.notdrop {
  position: absolute;
  top: 0;
  right: 66px;
  background-color: #1b2333;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  width: 314px;
  height: 365px;
  z-index: 100;
  margin-top: 80px;
}

.notifjh {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.4px;
}

.notiq {
  color: #cdcdcd;
  font-size: 14px;
  margin-top: 90px;
  text-align: center;
}

.user_frien2 {
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  background-color: #fefefe;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.user_frien3 {
  width: 53px;
  border-radius: 50px;
  background-color: #fff;
  padding: 2px;
}

.yools {
  margin: -3px 10px;
}

.lool {
  font-size: 19px;
}

.pricee {
  text-align: center;
  color: #ffffff;
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.4px;
  border: 0;
  border-radius: 50px;
  background: transparent;
  margin-right: 15px;
  border: 0;
  height: 38px;
  display: flex;
}

.sign_inout {
  text-align: center;
  color: #ffffff;
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 50px;
  background: transparent;
  border: 0;
  margin-right: 15px;
  height: 38px;
  display: flex;
}

.creditAvailable {
  color: #6cd97e;
  font-size: 16.5px;
  font-weight: 500;
  letter-spacing: 0.4px;
  border: 0;
  border-radius: 50px;
  background: transparent;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.dollarsign {
  color: #6cd97e;
  border-radius: 50px;
  padding: 3px 7px;
  margin-right: 0px;
  border: 1px solid #6cd97e;
  margin: 5px;
}

.iconni {
  width: 20px;
  height: 20px;
}

.credit_numberss {
  color: #6cd97e;
}

.mlm {
  margin-right: 8px;
  text-decoration: none;
}

.search {
  width: 290px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #8899a8;
  background: #1b2333;
  color: #9babc5;
  font-size: 14px;
  font-weight: 400;
  padding: 13px 20px;
}

.search_icon {
  width: 20.012px;
  height: 19.997px;
  flex-shrink: 0;
  cursor: pointer;
  margin: 12px -38px;
}

.input {
  display: flex;
}

.noti {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-top: 15px;
  cursor: pointer;
}

.profile_sys {
  display: flex;
  position: relative;
  cursor: pointer;
}

.img_pic {
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  background-color: #fefefe;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.profile {
  display: flex;
}

.looo {
  display: flex;
}

.menu {
  cursor: pointer;
  display: block;
}

.drop {
  display: flex;
  justify-content: right;
  width: 100%;
}

.below.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.hamburger {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 335px;
  background-color: #0d1525;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  padding: 0px 30px;
}

.hamburger_btns {
  display: grid;
  grid-gap: 6px;
  margin-top: 24px;
}

.close_btn_ham {
  cursor: pointer;
  margin: 12px 259px;
  padding: 7px;
}

.logoings {
  display: flex;
  margin-top: -23px;
}

.namingLogos {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  margin: 0px 18px;
}

.hmaicon {
  margin-left: 33px;
  font-size: 15px;
  margin-top: 1px;
  text-decoration: none;
}

@media screen and (max-width: 772px) {
  .header_component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 11px;
  }
}

@media screen and (max-width: 490px) {
  .hamburger {
    height: 100%;
    overflow-y: auto;
    width: 280px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
  }

  .close_btn_ham {
    cursor: pointer;
    margin: 12px 205px;
    padding: 7px;
    border-radius: 50%;
  }

  .exploreing {
    text-align: center;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-radius: 50px;
    background: transparent;
    margin-right: 15px;
    height: 38px;
    border: 0;
    display: flex;
  }

  .pricee {
    text-align: center;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    border: 0;
    border-radius: 50px;
    background: transparent;
    margin-right: 15px;
    border: 0;
    height: 38px;
    display: flex;
  }

  .sign_inout {
    text-align: center;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-radius: 50px;
    background: transparent;
    border: 0;
    margin-right: 15px;
    height: 38px;
    display: flex;
  }

  .creditAvailable {
    font-size: 14px;
    padding: 0px;
  }

  .iconni {
    width: 20px;
    height: 20px;
  }

  .hamburger_btns {
    display: grid;
    grid-gap: 13px;
    margin-top: 36px;
  }
}

@media screen and (max-width: 1157px) {
  .najvbh {
    display: none;
  }

  .input {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }

  .notifh {
    display: none;
  }

  .exploreing {
    text-align: center;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-radius: 50px;
    background: transparent;
    margin-right: 15px;
    height: 38px;
    border: 0;
    display: flex;
  }

  .pricee {
    text-align: center;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    border: 0;
    border-radius: 50px;
    background: transparent;
    margin-right: 15px;
    border: 0;
    height: 38px;
    display: flex;
  }

  .sign_inout {
    text-align: center;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-radius: 50px;
    background: transparent;
    border: 0;
    margin-right: 15px;
    height: 38px;
    display: flex;
  }

  .iconni {
    width: 20px;
    height: 20px;
  }

  .hamburger_btns {
    display: grid;
    grid-gap: 13px;
    margin-top: 36px;
  }
}

@media screen and (max-width: 1037px) {
  .najvbh {
    display: none;
  }

  .input {
    display: none;
  }

  .noti {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .input {
    display: none;
  }

  .najvbh {
    display: none;
  }

  .noti {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }
}

@media screen and (max-width: 699px) {
  .input {
    display: none;
  }

  .najvbh {
    display: none;
  }

  .noti {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }
}

@media screen and (max-width: 603px) {

  .input {
    display: none;
  }

  .najvbh {
    display: none;
  }

  .noti {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }
}

@media screen and (max-width: 457px) {
  .input {
    display: none;
  }

  .najvbh {
    display: none;
  }

  .img_pic {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    background-color: #fefefe;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }

  .noti {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .mainLogoIPs {
    width: 67px;
    height: 41px;
  }

  .noti {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-top: 15px;
    cursor: pointer;
  }

  .header_component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 8px 11px;
  }

  .btn_item_gig {
    display: none;
  }

  .hamburger {
    width: 240px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    border-bottom-left-radius: 20px;
  }

  .close_btn_ham {
    cursor: pointer;
    margin: 12px 166px;
    padding: 7px;
    border-radius: 50%;
  }
  .hmaicon {
    margin-left: 20px;
    font-size: 12px;
    margin-top: 0px;
  }

  .creditAvailable {
    font-size: 12px;
  }

  .lool {
    font-size: 18px;
  }

  .hamburger_btns {
    display: grid;
    grid-gap: 13px;
    margin-top: 25px;
  }
}

@media screen and (max-width: 370px) {
  .input {
    display: none;
  }

  .najvbh {
    display: none;
  }

  .img_pic {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    background-color: #fefefe;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }

  .noti {
    display: none;
  }

  .btn_item_gig {
    display: none;
  }
}

.notifh {
  width: 19px;
  height: 19px;
  margin: 12px 40px;
  cursor: pointer;
}

.notdrop {
  position: absolute;
  top: 0;
  right: 66px;
  background-color: #1b2333;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  width: 314px;
  height: 365px;
  z-index: 100;
  margin-top: 80px;
}

.notifjh {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.4px;
}

.notiq {
  color: #cdcdcd;
  font-size: 14px;
  margin-top: 90px;
  text-align: center;
}

.user_frien2 {
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  background-color: #fefefe;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.user_frien3 {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50px;
  background-color: #fff;
  padding: 2px;
}

.yools {
  margin: -3px 10px;
}

.lool {
  font-size: 22px;
}
