.App {
  background-color: #0d1525;
}

body {
  background-color: #0d1525;
}

html {
  background-color: #0d1525;
}

.chattrt {
  display: flex;
}

.css-k008qs {
  display: -webkit-box !important; /* Force only -webkit-flex to be applied */
}
